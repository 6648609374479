import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/templates/Layout/Layout.js';
import './global.css';
import Prices from "./pages/Prices/Prices";
import EditCodeLang from "./pages/Emailing/EditCodeLang/EditCodeLang";
import EditTemplate from "./pages/Emailing/EditTemplate/EditTemplate";
import AddEligibles from "./pages/Emailing/AddEligibles/AddEligibles";
import AddCodeLang from "./pages/Emailing/AddCodeLang/AddCodeLang";
import EditBatch from "./pages/Emailing/EditBatch/EditBatch";
import Batch from "./pages/Emailing/Batch/Batch";
import Batchs from "./pages/Emailing/Batchs/Batchs";
import AddTemplate from "./pages/Emailing/AddTemplate/AddTemplate";
import AddBatch from "./pages/Emailing/AddBatch/AddBatch";
import Campaign from "./pages/Emailing/Campaign/Campaign";
import EditCampaign from "./pages/Emailing/EditCampaign/EditCampaign";
import AddCampaign from "./pages/Emailing/AddCampaign/AddCampaign";
import Campaigns from "./pages/Emailing/Campaigns/Campaigns";
import Countries from "./pages/Emailing/Countries/Countries";
import { NotificationProvider } from "./NotificationContext";
import ProtectedRoute from "./components/templates/Auth/ProtectedRoute";
import { useAuth0 } from "@auth0/auth0-react";
import Profile from "./pages/Profile/Profile";
import DashboardEmailing from "./pages/Emailing/Dashboard/DashboardEmailing";
import Credentials from "./pages/Emailing/Credentials/Credentials";
import Logs from "./pages/Emailing/Logs/Logs";
import {jwtDecode} from "jwt-decode";
import NoAccess from "./pages/Emailing/NoAccess/NoAccess";
const App = () => {
    const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode(token);
                const userPermissions = decodedToken.permissions || [];
                setPermissions(userPermissions);
            } catch (e) {
                console.error(e);
            }
        };

        if (isAuthenticated) {
            fetchPermissions();
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <NotificationProvider>
            <Router>
                <Routes>
                    <Route path="/" element={
                        <ProtectedRoute requiredPermissions={['read:emailing:dashboard']} permissions={permissions}>
                            <Layout permissions={permissions}>
                                <DashboardEmailing />
                            </Layout>
                        </ProtectedRoute>
                    } />

                    {permissions && (
                        <>
                            <Route path="/profile" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <Profile />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/credentials" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <Credentials />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/countries" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <Countries />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/ajouter" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <AddCampaign />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId/modifier" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <EditCampaign />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <Campaign />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId/ajouter-batch" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <AddBatch />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId/ajouter-template" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <AddTemplate />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId/batch" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <Batchs />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId/batch/:batchId" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <Batch />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId/batch/:batchId/modifier" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <EditBatch />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId/declinaison" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <AddCodeLang />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId/batch/:batchId/ajouter-eligibles" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <AddEligibles />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId/edit-template/:templateId" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <EditTemplate />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes/:campaignId/code-lang/:codeLangId" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <EditCodeLang />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/prices" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <Prices />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/campagnes" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <Campaigns />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                            <Route path="/logs" element={
                                <ProtectedRoute requiredPermissions={['read:emailing']} permissions={permissions}>
                                    <Layout permissions={permissions}>
                                        <Logs />
                                    </Layout>
                                </ProtectedRoute>
                            } />
                        </>
                    )}

                    <Route path="/no-access" element={<NoAccess />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Router>
        </NotificationProvider>
    );
};

export default App;
