import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from "../../atoms/Loading/Loading";
import {jwtDecode} from "jwt-decode";

const ProtectedRoute = ({ children, requiredPermissions = [] }) => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [hasPermission, setHasPermission] = useState(false);
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const decodedToken = jwtDecode(accessToken);

                const userPermissions = decodedToken.permissions || [];
                const hasRequiredPermissions = requiredPermissions.every(permission =>
                    userPermissions.includes(permission)
                );

                setHasPermission(hasRequiredPermissions);
                setPermissionsLoaded(true);
            } catch (e) {
                console.error("Error getting access token:", e);
                setPermissionsLoaded(true); // even on error, we consider permissions as loaded
            }
        };

        if (isAuthenticated) {
            fetchAccessToken();
        }
    }, [isAuthenticated, getAccessTokenSilently, requiredPermissions]);

    if (isLoading || !permissionsLoaded) {
        return <Loading />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (!hasPermission) {
        return <Navigate to="/no-access" />;
    }

    return children;
};

export default ProtectedRoute;
